<template>
    <div class="wrap">
        <CancelUpdate v-if="cancelModalStatus" @closeCancel="cancelModalStatus = false" />
        <CurationInfo :curationInfo="curationInfo" v-if="showCurationInfo" @closeCurationInfo="showCurationInfo = false" />
        <CurationInfoM :curationInfo="curationInfo" v-if="showCurationInfoM" @closeCurationInfo="showCurationInfoM = false" />
        <div class="space_pc">
            <section class="main registration">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom:0px;">큐레이션 설정</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">선호하는 차량 분위기를 알려주세요.</h3>
                        </div>
                        <div class="pagnation">
                            <span></span>
                            <span style="width:42.84%"></span>
                        </div>
                        <div class="three_type fade-up" id="car_mood">
                            <div>
                                <ul class="acodian_tab" style="padding-left:0px;" @click="clickCarMood()">
                                    <li style="
                      background: url('/media/img/unsplash_VNsdEl1gORk.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carMood" value="1" hidden />
                                        <p style="margin-bottom:0px;">차분한 카페</p>
                                        <div :class="{ on: selectItem[0] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_Yrxr3bsPdS0.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carMood" value="2" hidden />
                                        <p style="margin-bottom:0px;">럭셔리한 호텔</p>
                                        <div :class="{ on: selectItem[1] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_TIdqm0Cl8UA.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carMood" value="3" hidden />
                                        <p style="margin-bottom:0px;">신나는 클럽</p>
                                        <div :class="{ on: selectItem[2] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_CQfNt66ttZM.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carMood" value="4" hidden />
                                        <p style="margin-bottom:0px;">활기찬 헬스장</p>
                                        <div :class="{ on: selectItem[3] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                    <li style="
                      background: url('/media/img/unsplash_0MoF-Fe0w0A.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                        <input type="checkbox" v-model="carMood" value="5" hidden />
                                        <p style="margin-bottom:0px;">힐링 스파샵</p>
                                        <div :class="{ on: selectItem[4] }">
                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="form_bottom space_1">
                            <div class="form_bottom_box">
                                <div>
                                    <svg @click="showCurationInfo = true" width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                              fill="white" />
                                    </svg>
                                    <div>
                                        <p style="margin-bottom:0px;text-decoration:none;" v-for="(item, i) in curationList" :key="i">{{ item }}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <button id="prev_btn" style="text-align:center;" @click="clickPre()">이전</button>
                                    <button id="next_btn" style="text-align:center;" v-if="carMood.carMoodNo"
                                            @click="updateCuration()">다음</button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a href="#" @click="clickPre()">
                        <img src="/media/images/icon_back_24.svg">
                    </a>
                    <h2>큐레이션 설정</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg">
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width:42.84%"></span>
                </div>
            </div>
            <section class="main registration type_choice">
                <div class="main_box">
                    <h1>큐레이션 설정</h1>
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="text-align:center;margin-bottom:0px;">선호하는 차량 분위기를 알려주세요.</h3>
                        </div>
                        <div class="types" @click="clickCarMoodM()">
                            <ul style="padding-left:0px;">
                                <li style="
                    background: url('/media/img/unsplash_VNsdEl1gORk.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[0] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carMood" value="1" hidden />
                                    <p style="text-align:center;margin-bottom:0px;">차분한 카페</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_Yrxr3bsPdS0.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[1] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carMood" value="2" hidden />
                                    <p style="text-align:center;margin-bottom:0px;">럭셔리한 호텔</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_TIdqm0Cl8UA.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[2] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carMood" value="3" hidden />
                                    <p style="text-align:center;margin-bottom:0px;">신나는 클럽</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_CQfNt66ttZM.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[3] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carMood" value="4" hidden />
                                    <p style="text-align:center;margin-bottom:0px;">활기찬 헬스장</p>
                                </li>
                                <li style="
                    background: url('/media/img/unsplash_0MoF-Fe0w0A.png') no-repeat
                      center 0;
                    background-size: cover;
                  ">
                                    <span :style="{ display: selectItem[4] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="carMood" value="5" hidden />
                                    <p style="text-align:center;margin-bottom:0px;">힐링 스파샵</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="types_bottom">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @click="showInfo('carMood')">
                            <path
                                d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                fill="white" />
                        </svg>
                        <div>
                            <p style="margin-bottom:0px;" v-for="(item, index) in curationInfo.carPurpose" :key="index">{{ item }}</p>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button style="text-align:center;" v-if="carMood.carMoodNo" @click="updateCuration()">다음</button>
                        <button class="opacity3" style="text-align:center;" v-else>다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import CurationInfo from "@/components/modal/Space/CurationInfo2.vue";
import CurationInfoM from "@/components/modal/Space/CurationInfoM.vue";
import CancelUpdate from "@/components/modal/Space/CancelUpdate.vue";
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";
import router from "@/router";

export default defineComponent({
  data () {
    return {
      showCurationInfo: false,
      showCurationInfoM: false,
      carMood: {
        carMoodNo: null
      },
      curationInfo: {
        infoStatus: '',
        carPurpose: []
      },
      curationList: [],
      tempList: [],
      allList: ['차분한 카페', '럭셔리한 호텔', '신나는 클럽', '활기찬 헬스장', '힐링 스파샵'],
      selectItem: [],
      filterList: [],
      cancelModalStatus: false
    };
  },
  components: {
    CurationInfo,
    CurationInfoM,
    CancelUpdate
  },
  methods: {
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.showCurationInfoM = true;
    },
    linkMySpace () {
      this.cancelModalStatus = true;
    },
    updateCuration () {
      var spaceId = localStorage.getItem("spaceId");
      var carMood = (this.carMood.carMoodNo).toString();
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/updateCurationTemp", { spaceId, carMood }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          router.push({ name: "MySpaceUpdateCountry" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickPre () {
      router.push({ name: "MySpaceUpdateCarPurpose" });
    },
    clickCarMood () {
      $(document).off("click").on("click", ".acodian_tab > li", (e) => {
        $(".acodian_tab > li").children("div").removeClass("on");
        $(e.currentTarget).children("div").toggleClass("on");
        // carMood 값 넣고 빼기
        this.carMood.carMoodNo = e.currentTarget.querySelector('input').value;
        this.carMood.moodNm = e.currentTarget.querySelector('p').textContent;
      });
    },
    clickCarMoodM () {
      $(document).off("click").on("click", ".types ul li", (e) => {
        $('.types ul li').children("span").css("display", 'none');
        $(e.currentTarget).children("span").toggle();
        this.carMood.carMoodNo = e.currentTarget.querySelector('input').value;
      });
    },
    openSelectBoxMo () {
      $('.types_bottom').find('svg').click(function () {
        $('.space_down_box1').css({ bottom: "0%", transition: "all 1s" });
      });
    },
    closeSelectBoxMo () {
      $(".space_down_box1").find('svg').click(function () {
        $(".space_down_box1").css({ bottom: "-100%", transition: "all 1s" });
      });
    },
    getCurationTemp () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/profile/getCurationTemp', { spaceId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.tempList = res.data.result.carMood;

          this.curationInfo.carPurpose = res.data.result.carPurpose;
          this.curationList = [...this.curationInfo.carPurpose];

          this.selectMood();
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectMood () {
      var allList = this.allList;
      for (var i in allList) {
        this.selectItem[i] = this.tempList.indexOf(allList[i]) > -1;
        // console.log(this.selectItem);
      }
      var filter = allList.indexOf(this.tempList) + 1;
      if (filter > 0) {
        this.carMood.carMoodNo = filter;
      }
    }
  },
  mounted () {
    this.getCurationTemp();
  }
});
</script>
<style scoped>
@media (max-width: 500px) {
  .types ul p {
    bottom: -30px;
  }

  .select_curation {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .select_curation p {
    padding: 10px 12px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 8px 8px;
    white-space: nowrap;
  }

  .types_bottom>svg {
    height: 15px;
  }
}
</style>
<style src="@/assets/css/content2.css">
#car_mood>div ul li::after {
  content: none;
}
</style>
<style src="@/assets/css/font.css"></style>
